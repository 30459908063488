import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

// https://www.dotnettricks.com/learn/angular/token-based-authentication-json-web-token-aspnet-core

@Component({
  templateUrl: './beregner-programmer.component.html',
  standalone: true,
  imports: [CommonModule]
})
export class BeregnerProgrammerComponent implements OnInit {

  constructor() {

  }

  ngOnInit(): void {
  }
}
