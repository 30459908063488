import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Observable, map, tap } from 'rxjs';
import { AppMaterialModule } from '../../../app-material.module';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { LogData, LogDataResponse } from '../../../models.generated/misc';
import { DcHDataTable } from '../../../models/dch-data-table';
import { HttpDataService } from '../../../services/http-data.service';
import { ConfirmationDialogService } from '../../../services/confirmation-dialog.service';
import { MatSnackBarService } from '../../../services/mat-snack-bar.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css'],
  standalone: true,
  imports: [CommonModule, AppMaterialModule, FormsModule, DcHDataTableComponent]
})
export class AdminLogsComponent implements OnInit {
  logDatas: LogData[];
  selectedLevels = ["Info", "Warn", "Error", "Fatal"];
  totalNoOfItems = 0;
  pageIndex = 0;
  pageSize = 25;
  dataTableDef = this.getDcHDataTable();

  constructor(readonly httpDataService: HttpDataService, private readonly dialogService: ConfirmationDialogService, private readonly matSnackBarService: MatSnackBarService) {
  }


  ngOnInit(): void {
    this.fetchData(this.selectedLevels, this.pageIndex, this.pageSize).subscribe();
  }

  onSelectionChange(): void {
    this.pageIndex = 0;
    this.fetchData(this.selectedLevels, this.pageIndex, this.pageSize).subscribe();;
  }

  handlePageEvent(e: PageEvent): void {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.fetchData(this.selectedLevels, this.pageIndex, this.pageSize).subscribe();;
  }

  downloadJsonFileClicked(logData: LogData): void {

    if (logData?.logFile == undefined || logData.logFile.length === 0) {
      console.error(logData);
      return;
    }

    const fileName = logData?.logFile;
    this.downloadFile$(fileName).subscribe();
  }

  deleteLogItemClicked(logData: LogData): void {
    let msg = `Slet ${logData.message}`;

    this.dialogService.showDialog(msg, 'Logs', 'OkCancel')
      .subscribe(a => {
        if (a) {
          this.deleteItem(logData).subscribe();
          let index = this.logDatas.findIndex(i => i.id === logData.id);
          this.logDatas = this.logDatas.filter(a => a.id != logData.id);
          this.matSnackBarService.showOkMsg('slettet');
          //data.startDate = { seconds: this.startDateFormControl.value.getTime() / 1000, nanos: 0 };
          //data.endDate = { seconds: this.endDateFormControl.value.getTime() / 1000, nanos: 0 };
          //this.postQualificationPeriod$(data).subscribe(() => this.disableSaveButton = true);
        }
      });
  }

  private downloadFile$(fileName: string): Observable<Blob> {

    const url = `api/Logs/DownloadFile?fileName=${fileName}`;

    return this.httpDataService.getBlob(url).pipe(
      tap(result => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(result);
        link.download = fileName;
        link.click();
      })
    );
  }

  private deleteItem(logData: LogData): Observable<number> {
    const url = `api/Logs/Delete?rowId=${logData.id}`;
    return this.httpDataService.deleteData<number>(url);
  }


  private fetchData(levels: string[], pageIndex: number, pageSize: number): Observable<LogData[]> {
    let myLevels = '?levels=';

    for (let i = 0; i < levels.length; i++) {
      if (i == 0) {
        myLevels = `?levels=${levels[i]}`;
      } else {
        myLevels += `&levels=${levels[i]}`;
      }
    }

    const url = `api/Logs/GetLogs${myLevels}&pageIndex=${pageIndex}&pageSize=${pageSize}`;

    return this.httpDataService.getData<LogDataResponse>(url)
      .pipe(
        map((a: LogDataResponse) => {
          this.totalNoOfItems = a.totalNoOfItems;
          this.logDatas = a.logDatas;
          return a.logDatas;
        })
      );
  }

  private getDcHDataTable(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.color = 'primary';
    dataTable.allowRowSelection = true;

    dataTable.columns = [
      { headerText: 'Date', field: 'createdOn', dataType: 'timeStamp', format: 'yyyyMMdd.HHmmss' },
      { headerText: 'Level', field: 'level' },
      { headerText: 'Logger', field: 'logger' },
      { headerText: 'Message', field: 'message', width: '40%', whiteSpace: 'normal' },
    ]

    return dataTable;
  }
}
