import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { HubConnectionStatusComponent } from '../../../components.shared/hub-connection-status/hub-connection-status.component';
import { LiveMessageComponent } from '../../../components.shared/live-message/live-message.component';
import { NoseWorkResultaterHelpers } from '../../../helpers/nosework-resultater.helpers';
import { DcHNoseWorkKlasse, KonkurrenceType } from '../../../models.generated/dchEnums';
import { NoseWorkLiveDataResponse, NoseWorkResult } from '../../../models.generated/nosework';
import { DcHCompetitionData } from '../../../models.generated/shared';
import { DcHDataTable } from '../../../models/dch-data-table';
import { SelectItem } from '../../../models/select-item';
import { DcHKonkurrenceArtText } from '../../../pipes/dch-konkurrence-art-text.pipe';
import { CompetitionDataService } from '../../../services/competition-data.service';
import { WebSocketService } from '../../../services/web-socket-service';

@Component({
  selector: 'app-nosework-live-results',
  templateUrl: './nosework-live-resultater.component.html',
  standalone: true,
  imports: [CommonModule, MatRadioModule, DcHKonkurrenceArtText, LiveMessageComponent, DcHSelectComponent, HubConnectionStatusComponent, DcHDataTableComponent]
})
export class NoseWorkLiveResultaterComponent implements OnInit, OnDestroy {
  liveDataResponse: NoseWorkLiveDataResponse | undefined;
  competionDataSelectItems = new Array<SelectItem<DcHCompetitionData>>();
  competionDataSelectedValue: DcHCompetitionData;
  klasseSelectItems: SelectItem<[DcHNoseWorkKlasse, number]>[];
  klasseSelectedValue: [DcHNoseWorkKlasse, number];
  klasseResults: NoseWorkResult[] | undefined;
  sortField: 'placer' | 'startNo' = 'placer';
  dataTableDef = this.getDcHDataTable(this.sortField, 0);
  resIndex = 0;
  klasseDataResultIndex = 0;
  private prevSelectedEventId = 0;
  private resultaterHelpers = new NoseWorkResultaterHelpers();

  constructor(private readonly location: Location, private readonly webSocketService: WebSocketService, private readonly competitionDataService: CompetitionDataService) {
  }

  ngOnInit(): void {

    this.webSocketService.connectToHub$().subscribe();

    this.competitionDataService.fetchLiveCompetitionDatasAsSelectItems$(KonkurrenceType.NW)
      .pipe(
        mergeMap(selectItems => {
          if (selectItems.length > 0) {
            this.competionDataSelectItems = selectItems;
            this.competionDataSelectedValue = this.competionDataSelectItems[0].value;
            this.prevSelectedEventId = this.competionDataSelectedValue.dchEventId;
            return this.webSocketService.initLiveResultater$(KonkurrenceType.NW, this.competionDataSelectedValue.dchEventId);
          }
          else {
            this.klasseResults = this.getEmptyKlasseResults();
            this.competionDataSelectItems = [];
            return of();
          }
        })
      ).subscribe();

    this.webSocketService.noseworkLiveDataUpdated$
      .subscribe(liveDataReply => {
        console.log('NoseworkLiveDataUpdated: ' + liveDataReply.liveDataResponse?.dchEventId);

        this.liveDataResponse = liveDataReply;
        this.setklasseSelectItems(this.liveDataResponse, this.klasseSelectedValue);
        this.klasseDataResultsSelectionHandler(this.liveDataResponse, this.klasseSelectedValue[0], this.klasseSelectedValue[1]);
      });
  }

  async ngOnDestroy(): Promise<void> {
    this.webSocketService.unsubscribeLiveResultater$().subscribe();
  }

  selectionChangeHandler(): void {
    let currentEventId = this.competionDataSelectedValue.dchEventId;

    if (this.prevSelectedEventId === currentEventId) {
      if (this.liveDataResponse != undefined) {
        this.klasseDataResultsSelectionHandler(this.liveDataResponse, this.klasseSelectedValue[0], this.klasseSelectedValue[1]);
      }
    } else {
      this.liveDataResponse = undefined;
      this.klasseResults = this.getEmptyKlasseResults();
      this.webSocketService.changeLiveResultaterGroup$(currentEventId).subscribe();
      this.prevSelectedEventId = currentEventId;
    }

    this.updateUrl(currentEventId);
  }


  onClickMe(sortField: 'placer' | 'startNo' = 'placer'): void {
    this.sortField = sortField;
    if (this.liveDataResponse != undefined) {
      this.resultaterHelpers.sortKlasseDataResults(this.liveDataResponse.klasseDataResults[this.klasseDataResultIndex], this.resIndex, sortField);
      this.dataTableDef = this.getDcHDataTable(this.sortField, 0);
    }
  }

  private getEmptyKlasseResults(): NoseWorkResult[] {
    return new Array<NoseWorkResult>();
  }

  private updateUrl(konkId: number): void {
    const url = `/noseWorkLiveResultater/${konkId}`;
    this.location.replaceState(url);
  }

  private klasseDataResultsSelectionHandler(competitionResponse: NoseWorkLiveDataResponse, klasse: DcHNoseWorkKlasse, index: number): void {
    this.klasseDataResultIndex = competitionResponse.klasseDataResults.findIndex(b => b.klasse == klasse);
    this.resIndex = index;
    this.resultaterHelpers.sortKlasseDataResults(competitionResponse.klasseDataResults[this.klasseDataResultIndex], this.resIndex, this.sortField);
    this.dataTableDef = this.getDcHDataTable(this.sortField, index);
    this.klasseResults = competitionResponse.klasseDataResults[this.klasseDataResultIndex].noseWorkResults;
    this.dataTableDef = this.getDcHDataTable(this.sortField, index);
  }

  private setklasseSelectItems(competitionResponse: NoseWorkLiveDataResponse, curSelected: [DcHNoseWorkKlasse, number] | undefined): void {
    this.klasseSelectItems = this.resultaterHelpers.getKlasseSelectItems(competitionResponse.klasseDataResults, true);
    if (curSelected == undefined) {
      this.klasseSelectedValue = this.klasseSelectItems[0].value;
    } else {
      this.klasseSelectedValue = curSelected;
    }
  }

  private getDcHDataTable(columnChooser: 'placer' | 'startNo', resIndex: number): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.NW;
    dataTable.color = 'primary';
    dataTable.noDataText = 'Ingen resultater';

    dataTable.columns = [
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
      { headerText: 'Point', field: `noseWorkResultMoments[${resIndex}].point`, textalign: 'center', dataType: 'number', format: '1.1-1', },
      { headerText: 'Fejl', field: `noseWorkResultMoments[${resIndex}].fejl`, textalign: 'center', dataType: 'number', format: '1.0-0', },
      { headerText: 'Tid', field: `noseWorkResultMoments[${resIndex}].tid`, textalign: 'center', dataType: 'duration', format: 'm:ss.S', },
    ];

    if (columnChooser === 'startNo') {
      dataTable.columns.unshift({ headerText: '#', field: `noseWorkResultMoments[${resIndex}].startNo`, dataType: 'number', textalign: 'center' });
    } else {
      dataTable.columns.unshift({ headerText: '#', field: `noseWorkResultMoments[${resIndex}].placer`, dataType: 'dchPlacer', textalign: 'center' });
    }

    return dataTable;
  }
}
