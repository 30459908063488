<div class="pageHeader">
  <h1>Logs</h1>
  <div class="flex-container">
    <mat-form-field appearance="fill">
      <mat-label>Level</mat-label>
      <mat-select [(ngModel)]="selectedLevels" (selectionChange)="onSelectionChange()" multiple>
        <mat-option value="Trace">Trace</mat-option>
        <mat-option value="Debug">Debug</mat-option>
        <mat-option value="Info">Info</mat-option>
        <mat-option value="Warn">Warn</mat-option>
        <mat-option value="Error">Error</mat-option>
        <mat-option value="Fatal">Fatal</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="pageBody">
  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="logDatas" [dataRowDetails]="dataRowDetails" [rowMenuItems]="rowMenuItems"></app-dch-data-table>
</div>

<ng-template #dataRowDetails let-data>
  <mat-card>
    <mat-card-content>
      <p>{{data.appName}}</p>
      <p>{{data.url}}</p>
      <p>{{data.userName}}</p>
      <p>{{data.message}}</p>
      <p style="white-space: pre">{{data.exception}}</p>
      <p>{{data.logFile}}</p>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #rowMenuItems let-data>
  <button mat-menu-item (click)="downloadJsonFileClicked(data)" [disabled]="data.logFile==undefined || data.logFile.length === 0"><mat-icon>pageview</mat-icon> Vis log</button>
  <button mat-menu-item (click)="deleteLogItemClicked(data)" ><mat-icon>delete</mat-icon> Slet</button>
</ng-template>

<mat-paginator #paginator [length]="totalNoOfItems"
               showFirstLastButtons="true"
               [pageSize]="pageSize"
               [pageIndex]="pageIndex"
               [pageSizeOptions]="[25, 50, 100]"
               (page)="handlePageEvent($event)"
               aria-label="Select page">
</mat-paginator>



