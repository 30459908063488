// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.1
//   protoc               v4.24.4
// source: dchEnums.proto

/* eslint-disable */

export const protobufPackage = "DcHWebResProtobuf.enums";

export enum Severity {
  SeverityNone = 0,
  SeverityMinor = 1,
  SeverityMajor = 2,
  UNRECOGNIZED = -1,
}

export enum DcHAgilityKlasse {
  DcHAgilityKlasseNone = 0,
  AgilityKl1Lille = 10000,
  AgilityKl1XLille = 10001,
  AgilityKl1LilleJunior = 10100,
  AgilityKl1XLilleJunior = 10101,
  AgilityKl1Mellem = 11000,
  AgilityKl1XMellem = 11001,
  AgilityKl1MellemJunior = 11100,
  AgilityKl1XMellemJunior = 11101,
  AgilityKl1Intermediate = 12000,
  AgilityKl1XIntermediate = 12001,
  AgilityKl1IntermediateJunior = 12100,
  AgilityKl1XIntermediateJunior = 12101,
  AgilityKl1Stor = 13000,
  AgilityKl1XStor = 13001,
  AgilityKl1StorJunior = 13100,
  AgilityKl1XStorJunior = 13101,
  AgilityKl2Lille = 20000,
  AgilityKl2XLille = 20001,
  AgilityKl2LilleSenior = 20200,
  AgilityKl2XLilleSenior = 20201,
  AgilityKl2Mellem = 21000,
  AgilityKl2XMellem = 21001,
  AgilityKl2MellemSenior = 21200,
  AgilityKl2XMellemSenior = 21201,
  AgilityKl2Intermediate = 22000,
  AgilityKl2XIntermediate = 22001,
  AgilityKl2IntermediateSenior = 22200,
  AgilityKl2XIntermediateSenior = 22201,
  AgilityKl2Stor = 23000,
  AgilityKl2XStor = 23001,
  AgilityKl2StorSenior = 23200,
  AgilityKl2XStorSenior = 23201,
  AgilityKl3Lille = 30000,
  AgilityKl3XLille = 30001,
  AgilityKl3Mellem = 31000,
  AgilityKl3XMellem = 31001,
  AgilityKl3Intermediate = 32000,
  AgilityKl3XIntermediate = 32001,
  AgilityKl3Stor = 34000,
  AgilityKl3XStor = 34001,
  AgilityOpenLille = 40000,
  AgilityOpenXLille = 40001,
  AgilityOpenLilleSenior = 40200,
  AgilityOpenXLilleSenior = 40201,
  AgilityOpenMellem = 41000,
  AgilityOpenXMellem = 41001,
  AgilityOpenMellemSenior = 41200,
  AgilityOpenXMellemSenior = 41201,
  AgilityOpenIntermediate = 42000,
  AgilityOpenXIntermediate = 42001,
  AgilityOpenIntermediateSenior = 42200,
  AgilityOpenXIntermediateSenior = 42201,
  AgilityOpenStor = 43000,
  AgilityOpenXStor = 43001,
  AgilityOpenStorSenior = 43200,
  AgilityOpenXStorSenior = 43201,
  SpringKl1Lille = 50000,
  SpringKl1XLille = 50001,
  SpringKl1LilleJunior = 50100,
  SpringKl1XLilleJunior = 50101,
  SpringKl1Mellem = 51000,
  SpringKl1XMellem = 51001,
  SpringKl1MellemJunior = 51100,
  SpringKl1XMellemJunior = 51101,
  SpringKl1Intermediate = 52000,
  SpringKl1XIntermediate = 52001,
  SpringKl1IntermediateJunior = 52100,
  SpringKl1XIntermediateJunior = 52101,
  SpringKl1Stor = 53000,
  SpringKl1XStor = 53001,
  SpringKl1StorJunior = 53100,
  SpringKl1XStorJunior = 53101,
  SpringKl2Lille = 60000,
  SpringKl2XLille = 60001,
  SpringKl2LilleSenior = 60200,
  SpringKl2XLilleSenior = 60201,
  SpringKl2Mellem = 61000,
  SpringKl2XMellem = 61001,
  SpringKl2MellemSenior = 61200,
  SpringKl2XMellemSenior = 61201,
  SpringKl2Intermediate = 62000,
  SpringKl2XIntermediate = 62001,
  SpringKl2IntermediateSenior = 62200,
  SpringKl2XIntermediateSenior = 62201,
  SpringKl2Stor = 63000,
  SpringKl2XStor = 63001,
  SpringKl2StorSenior = 63200,
  SpringKl2XStorSenior = 63201,
  SpringKl3Lille = 70000,
  SpringKl3XLille = 70001,
  SpringKl3Mellem = 71000,
  SpringKl3XMellem = 71001,
  SpringKl3Intermediate = 72000,
  SpringKl3XIntermediate = 72001,
  SpringKl3Stor = 73000,
  SpringKl3XStor = 73001,
  SpringOpenLille = 80000,
  SpringOpenXLille = 80001,
  SpringOpenLilleSenior = 80200,
  SpringOpenXLilleSenior = 80201,
  SpringOpenMellem = 81000,
  SpringOpenXMellem = 81001,
  SpringOpenMellemSenior = 81200,
  SpringOpenXMellemSenior = 81201,
  SpringOpenIntermediate = 82000,
  SpringOpenXIntermediate = 82001,
  SpringOpenIntermediateSenior = 82200,
  SpringOpenXIntermediateSenior = 82201,
  SpringOpenStor = 83000,
  SpringOpenXStor = 83001,
  SpringOpenStorSenior = 83200,
  SpringOpenXStorSenior = 83201,
  DmBFinaleLille = 100000,
  DmBFinaleMellem = 101000,
  DmBFinaleIntermediate = 10200,
  DmBFinaleStor = 10300,
  DmAFinaleLille = 110000,
  DmAFinaleMellem = 111000,
  DmAFinaleIntermediate = 112000,
  DmAFinaleStor = 113000,
  SumB2oLille = 200000,
  SumB2oLilleSenior = 200200,
  SumB2oMellem = 201000,
  SumB2oMellemSenior = 201200,
  SumB2oIntermediate = 202000,
  SumB2oIntermediateSenior = 202200,
  SumB2oStor = 203000,
  SumB2oStorSenior = 203200,
  SumB4rLille = 210000,
  SumB4rLilleJunior = 210100,
  SumB4rLilleSenior = 210200,
  SumB4rMellem = 211000,
  SumB4rMellemJunior = 211100,
  SumB4rMellemSenior = 211200,
  SumB4rIntermediate = 212000,
  SumB4rIntermediateJunior = 212100,
  SumB4rIntermediateSenior = 212200,
  SumB4rStor = 213000,
  SumB4rStorJunior = 213100,
  SumB4rStorSenior = 213200,
  SumB4oLille = 220000,
  SumB4oLilleSenior = 220200,
  SumB4oMellem = 221000,
  SumB4oMellemSenior = 221200,
  SumB4oIntermediate = 222000,
  SumB4oIntermediateSenior = 222200,
  SumB4oStor = 223000,
  SumB4oStorSenior = 223200,
  UNRECOGNIZED = -1,
}

export enum DcHNoseWorkKlasseMoment {
  DcHNoseWorkKlasseMomentNone = 0,
  NW1Total = 1,
  NW1Moment1 = 2,
  NW1Moment2 = 3,
  NW1Moment3 = 4,
  NW1Moment4 = 5,
  NW2Total = 6,
  NW2Moment1 = 7,
  NW2Moment2 = 8,
  NW2Moment3 = 9,
  NW2Moment4 = 10,
  NW3Total = 11,
  NW3Moment1 = 12,
  NW3Moment2 = 13,
  NW3Moment3 = 14,
  NW3Moment4 = 15,
  NWEliteTotal = 16,
  NWEliteMoment1 = 17,
  NWEliteMoment2 = 18,
  NWEliteMoment3 = 19,
  NWEliteMoment4 = 20,
  NWSeniorTotal = 21,
  NWSeniorMoment1 = 22,
  NWSeniorMoment2 = 23,
  NWSeniorMoment3 = 24,
  NWSeniorMoment4 = 25,
  NWOpenTotal = 26,
  NWOpenMoment1 = 27,
  NWOpenMoment2 = 28,
  NWOpenMoment3 = 29,
  NWOpenMoment4 = 30,
  UNRECOGNIZED = -1,
}

export enum DcHNoseWorkKlasse {
  DcHNoseWorkKlasseNone = 0,
  NW1 = 1,
  NW2 = 2,
  NW3 = 3,
  NWElite = 4,
  NWSenior = 5,
  NWOpen = 6,
  UNRECOGNIZED = -1,
}

export enum DcHBrugsKlasse {
  DcHBrugsKlasseNone = 0,
  IGP1 = 1,
  IGP2 = 2,
  IGP3 = 3,
  IGPFH = 4,
  IFH1 = 5,
  IFH2 = 6,
  IFHV = 7,
  BH = 8,
  AD = 9,
  BegynderB = 10,
  BegynderAB = 11,
  IBGH1 = 12,
  IBGH2 = 13,
  IBGH3 = 14,
  FPr1 = 15,
  FPr2 = 16,
  FPr3 = 17,
  UPr1 = 18,
  UPr2 = 19,
  UPr3 = 20,
  SPr1 = 21,
  SPr2 = 22,
  SPr3 = 23,
  StPr1 = 24,
  StPr2 = 25,
  StPr3 = 26,
  IFH3 = 27,
  UNRECOGNIZED = -1,
}

export enum DcHNordiskKlasse {
  DcHNordiskKlasseNone = 0,
  LilleNordiskSpor = 1,
  LilleNordiskRunder = 2,
  NordiskSpor = 3,
  NordiskRunder = 4,
  NordiskRapportering = 5,
  NordiskSkydd = 6,
  UNRECOGNIZED = -1,
}

export enum DcHRallyKlasse {
  DcHRallyKlasseNone = 0,
  Beginner = 1,
  Practiced = 2,
  Expert = 3,
  Champion = 4,
  Open = 5,
  DM1Run1 = 100,
  DM1Run2 = 101,
  DM1Sum = 105,
  DMFinale = 200,
  DMTotal = 205,
  UNRECOGNIZED = -1,
}

export enum DcHLydighedKlasse {
  DcHLydighedKlasseNone = 0,
  A = 1,
  B = 2,
  C = 3,
  E = 4,
  L1 = 5,
  L2 = 6,
  UNRECOGNIZED = -1,
}

export enum DcHKlasseType {
  DcHKlasseTypeNone = 0,
  Agility3Year = 1,
  AgilitySenYear = 2,
  LydABCE = 3,
  LydIPG3 = 4,
  LydIGPFH = 5,
  LydETurUdtag = 6,
  RallyKlasse = 7,
  AgilityKlasse = 8,
  NordiskKlasse = 9,
  LydCOprykkere = 10,
  NoseWorkKlasse = 11,
  UNRECOGNIZED = -1,
}

export enum KonkurrenceType {
  None = 0,
  AG = 1,
  LY = 2,
  RA = 3,
  NO = 4,
  BR = 5,
  NW = 6,
  HO = 7,
  UNRECOGNIZED = -1,
}

export enum NordiskGodkendt {
  IG = 0,
  G = 1,
  G1 = 2,
  G2 = 3,
  UNRECOGNIZED = -1,
}

export enum IPODSB {
  IPODSBNone = 0,
  UDP = 1,
  TS = 2,
  UTS = 3,
  UNRECOGNIZED = -1,
}

export enum LiveDataStatus {
  Ok = 0,
  NotActive = 1,
  UnknownCompetition = 2,
  UNRECOGNIZED = -1,
}

export enum DcHKonkurrenceArt {
  DcHKonkurrenceArtNone = 0,
  Lokal = 1,
  Kreds = 2,
  Lands = 3,
  DM = 4,
  Eliteturnering = 5,
  JM = 6,
  NordiskMesterskab = 7,
  BHU = 8,
  FM = 9,
  SM = 10,
  UNRECOGNIZED = -1,
}

export enum DcHKreds {
  DcHLands = 0,
  Kreds1 = 1,
  Kreds2 = 2,
  Kreds3 = 3,
  Kreds4 = 4,
  Kreds5 = 5,
  Kreds6 = 6,
  UNRECOGNIZED = -1,
}

export enum KlubmodulStatus {
  KlubmodulStatusNone = 0,
  Member = 1,
  NotMember = 2,
  Deleted = 3,
  UNRECOGNIZED = -1,
}

export enum AgilitySize {
  AgilitySizeNone = 0,
  Lille = 1,
  Mellem = 2,
  Stor = 3,
  Intermediate = 4,
  UNRECOGNIZED = -1,
}

export enum HoopersSize {
  HoopersSizeNone = 0,
  HoopersSizeSmall = 1,
  HoopersSizeLarge = 2,
  UNRECOGNIZED = -1,
}

export enum RallyHeight {
  RallyHeightNone = 0,
  H15 = 15,
  H25 = 25,
  H40 = 40,
  UNRECOGNIZED = -1,
}

export enum AgilityAlder {
  Adult = 0,
  Junior = 1,
  Senior = 2,
  UNRECOGNIZED = -1,
}

export enum HoopersAlder {
  HoopersAlderAdult = 0,
  HoopersAlderJunior = 1,
  HoopersAlderSenior = 2,
  UNRECOGNIZED = -1,
}

export enum DmUdtagAgility {
  DmUdtagAgilityAuto = 0,
  DmUdtagAgilityIkke = -1,
  InviteAsSmall = 100,
  InviteAsMedium = 101,
  InviteAsIntermediate = 102,
  InviteAsLarge = 103,
}

export enum DmUdtagLydighed {
  DmUdtagLydighedAuto = 0,
  DmUdtagLydighedIkke = -1,
  UdtagetLydighedKreds = 1,
  UdtagetLydighedLands = 2,
  InviteAsC = 100,
  InviteAsB = 101,
  InviteAsA = 102,
  InviteAsE = 103,
  UdtagetLydighedReserve = 200,
  UdtagetLydighedListe = 300,
}

export enum DmUdtagRally {
  DmUdtagRallyAuto = 0,
  DmUdtagRallyIkke = -1,
  UdtagetRallyKreds = 1,
  UdtagetRallyLands = 2,
  InviteAsLilleVeteran = 100,
  InviteAsMellemStor = 101,
  UdtagetRallyReserve = 200,
  UdtagetRallyListe = 300,
}

export enum UdtagETur {
  UdtagETurAuto = 0,
  UdtagETurIkke = -1,
  InviteAsETur = 100,
}

export enum DmUdtagNordisk {
  DmUdtagNordiskAuto = 0,
  DmUdtagNordiskIkke = -1,
  UdtagetNordiskKreds = 1,
  UdtagetNordiskLands = 2,
  InviteAsSpor = 100,
  InviteAsRunder = 101,
  UdtagetNordiskReserve = 200,
}

export enum DmUdtagIpg3 {
  DmUdtagIpg3Auto = 0,
  DmUdtagIpg3Ikke = -1,
  UdtagetIgp3Lands = 2,
  InviteAsIpg3 = 100,
  UdtagetIgp3Reserve = 200,
}

export enum DmUdtagIgpFh {
  DmUdtagIgpFhAuto = 0,
  DmUdtagIgpFhIkke = -1,
  UdtagetIgpFhLands = 2,
  InviteAsIgpFh = 100,
  UdtagetIgpFhReserve = 200,
}

export enum DmUdtagNoseWork {
  DmUdtagNoseWorkAuto = 0,
  DmUdtagNoseWorkIkke = -1,
  UdtagetNoseWorkKreds = 1,
  UdtagetNoseWorkLands = 2,
  InviteAsNoseWork = 100,
  UdtagetNoseWorkReserve = 200,
  UdtagetNoseWorkListe = 300,
}

export enum HoopersKlasse {
  HoopersKlasseNone = 0,
  BeginnerRun1 = 1,
  BeginnerRun2 = 2,
  BeginnerRun3 = 3,
  BeginnerRun4 = 4,
  BeginnerRun5 = 5,
  BeginnerRun6 = 6,
  PracticedRun1 = 7,
  PracticedRun2 = 8,
  PracticedRun3 = 9,
  PracticedRun4 = 10,
  PracticedRun5 = 11,
  PracticedRun6 = 12,
  ExpertRun1 = 13,
  ExpertRun2 = 14,
  ExpertRun3 = 15,
  ExpertRun4 = 16,
  ExpertRun5 = 17,
  ExpertRun6 = 18,
  ChampionRun1 = 19,
  ChampionRun2 = 20,
  ChampionRun3 = 21,
  ChampionRun4 = 22,
  ChampionRun5 = 23,
  ChampionRun6 = 24,
  OpenRun1 = 25,
  OpenRun2 = 26,
  UNRECOGNIZED = -1,
}
