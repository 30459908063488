<div class="pageHeader">
  <h1>Download DcH beregnerprogrammer</h1>
</div>

<div class="pageBody">
  <p>Download af beregnerprogrammerne er flyttet til Windows Store</p>
  Sådan opdaterer du:

  <ol>
    <li>Afinstaller den gamle version af beregnerprogrammet</li>
    <li>Skriv <i>Store</i> i Windows søgefelt</li>
    <li>I Windows store søg f.eks efter <i>DcH</i></li>
    <li>Installer beregnerprogrammet</li>
  </ol>

  <a href="https://dch-danmark.dk/cms/Clubdchdanmarkregnskab/ClubImages//KU/24924Nyhedsbrevlandskonkurrenceudvalget12024.pdf">Nyhedsbrev 1-2024 fra Konkurrenceudvalget</a>
</div>

