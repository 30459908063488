import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { HubConnectionStatusComponent } from '../../../components.shared/hub-connection-status/hub-connection-status.component';
import { LiveMessageComponent } from '../../../components.shared/live-message/live-message.component';
import { DcHRallyKlasse, KonkurrenceType } from '../../../models.generated/dchEnums';
import { RallyKlasseDataResults, RallyLiveDataResponse } from '../../../models.generated/rally';
import { DcHCompetitionData } from '../../../models.generated/shared';
import { DcHDataTable } from '../../../models/dch-data-table';
import { SelectItem } from '../../../models/select-item';
import { DcHKonkurrenceArtText } from '../../../pipes/dch-konkurrence-art-text.pipe';
import { CompetitionDataService } from '../../../services/competition-data.service';
import { WebSocketService } from '../../../services/web-socket-service';
import { RallyLiveResultaterHelpers } from './rally-live-resultater.helpers';

@Component({
  selector: 'app-rally-live-results',
  templateUrl: './rally-live-resultater.component.html',
  standalone: true,
  imports: [
    CommonModule, MatRadioModule, MatIconModule, DcHKonkurrenceArtText, LiveMessageComponent, DcHSelectComponent, HubConnectionStatusComponent, DcHDataTableComponent]
})
export class RallyLiveResultaterComponent implements OnInit, OnDestroy {
  liveDataResponse: RallyLiveDataResponse | undefined;
  competionDataSelectItems = new Array<SelectItem<DcHCompetitionData>>();
  competionDataSelectedValue: DcHCompetitionData;
  klasseSelectItems: SelectItem<DcHRallyKlasse>[];
  klasseSelectedValue = DcHRallyKlasse.DcHRallyKlasseNone;
  klasseResults: RallyKlasseDataResults | undefined;
  sortField: 'placer' | 'startNo' = 'placer';
  dataTableDef = this.getDcHDataTable(this.sortField);
  private prevSelectedEventId = 0;
  private liveResultaterHelpers = new RallyLiveResultaterHelpers();

  constructor(private readonly location: Location, private readonly webSocketService: WebSocketService, private readonly competitionDataService: CompetitionDataService) {
  }

  ngOnInit(): void {

    this.webSocketService.connectToHub$().subscribe();

    this.competitionDataService.fetchLiveCompetitionDatasAsSelectItems$(KonkurrenceType.RA)
      .pipe(
        mergeMap(selectItems => {
          if (selectItems.length > 0) {
            this.competionDataSelectItems = selectItems;
            this.competionDataSelectedValue = this.competionDataSelectItems[0].value;
            this.prevSelectedEventId = this.competionDataSelectedValue.dchEventId;
            return this.webSocketService.initLiveResultater$(KonkurrenceType.RA, this.competionDataSelectedValue.dchEventId);
          }
          else {
            this.klasseResults = this.getEmptyKlasseResults();
            this.competionDataSelectItems = [];
            return of();
          }
        })
      ).subscribe();

    this.webSocketService.rallyLiveDataUpdated$
      .subscribe(liveDataReply => {
        console.log('RallyLiveDataUpdated: ' + liveDataReply.liveDataResponse?.dchEventId);

        this.liveDataResponse = liveDataReply;
        this.klasseSelectItems = liveDataReply.klasseDataResults.map(item => this.liveResultaterHelpers.getKlasseSelectItem(item.klasse));
        this.klasseSelectedValue = this.liveResultaterHelpers.getKlasseSelectValue(this.klasseSelectItems, this.klasseSelectedValue);
        this.klasseResults = this.getFilteredData(this.klasseSelectedValue, this.sortField);
      });
  }

  async ngOnDestroy(): Promise<void> {
    this.webSocketService.unsubscribeLiveResultater$().subscribe();
  }

  selectionChangeHandler(): void {
    let currentEventId = this.competionDataSelectedValue.dchEventId;

    if (this.prevSelectedEventId === currentEventId) {
      this.klasseResults = this.getFilteredData(this.klasseSelectedValue, this.sortField);
    } else {
      this.liveDataResponse = undefined;
      this.klasseResults = this.getEmptyKlasseResults();
      this.webSocketService.changeLiveResultaterGroup$(currentEventId).subscribe();
      this.prevSelectedEventId = currentEventId;
    }

    this.updateUrl(currentEventId);
  }

  onClickMe(sortField: 'placer' | 'startNo' = 'placer'): void {
    this.sortField = sortField;
    this.klasseResults = this.getFilteredData(this.klasseSelectedValue, this.sortField);
    this.dataTableDef = this.getDcHDataTable(this.sortField);
  }

  private getEmptyKlasseResults(): RallyKlasseDataResults {
    let a = {} as RallyKlasseDataResults;
    a.rallyResults = [];
    return a;
  }

  private updateUrl(konkId: number): void {
    const url = `/rallyLiveResultater/${konkId}`;
    this.location.replaceState(url);
  }

  private getFilteredData(klasse: DcHRallyKlasse, sortOrder: 'placer' | 'startNo'): RallyKlasseDataResults | undefined {

    if (this.liveDataResponse == undefined || klasse == undefined) {
      return undefined;
    }

    const klasseDataResults = this.liveDataResponse.klasseDataResults.find(a => a.klasse === klasse);

    if (klasseDataResults?.rallyResults != undefined) {
      this.liveResultaterHelpers.sortKlasseDataResults(klasseDataResults.rallyResults, sortOrder);
    }

    return klasseDataResults;
  }

  private getDcHDataTable(columnChooser: 'placer' | 'startNo'): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.RA;
    dataTable.color = 'primary';
    dataTable.noDataText = 'Ingen resultater';

    dataTable.columns = [
      { headerText: '#', field: 'placer', dataType: 'dchPlacer', textalign: 'center' },
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
      { headerText: 'Tid', field: 'tid', textalign: 'center', dataType: 'duration', format: 'mm:ss' },
      { headerText: 'Point', field: 'point', textalign: 'center' },
      { headerText: 'Bestået', field: 'bestaet', textalign: 'center', dataType: 'mat-icon', fnFormatter: (a: boolean) => a ? 'done' : '' }
    ];

    if (columnChooser === 'startNo') {
      dataTable.columns.unshift({ headerText: '#', field: 'startNo', dataType: 'number', textalign: 'center' });
    } else {
      dataTable.columns.unshift({ headerText: '#', field: 'placer', dataType: 'dchPlacer', textalign: 'center' });
    }

    return dataTable;
  }
}
