import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { SelectCompetitionComponent } from '../../../components.shared/select-competition/select-competition.component';
import { NoseWorkResultaterHelpers } from '../../../helpers/nosework-resultater.helpers';
import { DcHNoseWorkKlasse, KonkurrenceType } from '../../../models.generated/dchEnums';
import { NoseWorkCompetitionResponse, NoseWorkResult } from '../../../models.generated/nosework';
import { DcHCompetitionData } from '../../../models.generated/shared';
import { DcHDataTable } from '../../../models/dch-data-table';
import { SelectItem } from '../../../models/select-item';
import { DcHKonkurrenceArtText } from '../../../pipes/dch-konkurrence-art-text.pipe';
import { CompetitionDataService } from '../../../services/competition-data.service';

@Component({
  selector: 'app-nosework-results',
  templateUrl: './nosework-resultater.component.html',
  standalone: true,
  imports: [CommonModule, DcHSelectComponent, DcHKonkurrenceArtText, SelectCompetitionComponent, DcHDataTableComponent]
})

export class NoseWorkResultaterComponent implements OnInit {
  competitionResponse: NoseWorkCompetitionResponse;
  competionDataSelectItems: SelectItem<DcHCompetitionData>[];
  competionDataSelectedValue: DcHCompetitionData;
  klasseSelectItems: SelectItem<[DcHNoseWorkKlasse, number]>[];
  klasseSelectedValue: [DcHNoseWorkKlasse, number];
  klasseResults: NoseWorkResult[] | undefined;
  dataTableDef = this.getDcHDataTable(0);
  private klasseDataResultIndex = 0;
  private prevSelectedKonkId = 0;
  private resultaterHelpers = new NoseWorkResultaterHelpers();

  @Input()
  importMode = false;

  @Input()
  competitionResponses: NoseWorkCompetitionResponse[];

  constructor(private readonly competitionDataService: CompetitionDataService) {
  }

  ngOnInit(): void {
    if (this.importMode) {
      this.copyEventIdToKonkId(this.competitionResponses);
      this.competitionResponse = this.competitionResponses[0];
      this.competionDataSelectItems = this.competitionResponses.map(a => this.competitionDataService.getCompetitionDataSelectItem(a.competitionData));
      this.competionDataSelectedValue = this.competionDataSelectItems[0].value;
      this.prevSelectedKonkId = this.competionDataSelectedValue.konkId;
      this.setklasseSelectItems(this.competitionResponse);
      this.klasseDataResultsSelectionHandler(this.competitionResponse, this.klasseSelectedValue[0], this.klasseSelectedValue[1]);
    }
  }

  selectionChangeHandler(): void {

    if (this.prevSelectedKonkId === this.competionDataSelectedValue.konkId) {
      this.klasseDataResultsSelectionHandler(this.competitionResponse, this.klasseSelectedValue[0], this.klasseSelectedValue[1]);
    }
    else if (this.importMode) {
      let newCompetitionResponse = this.competitionResponses.find(a => a.competitionData?.konkId == this.competionDataSelectedValue.konkId);
      if (newCompetitionResponse != undefined) {
        this.competitionResponse = newCompetitionResponse;
        this.setklasseSelectItems(this.competitionResponse);
        this.klasseDataResultsSelectionHandler(this.competitionResponse, this.klasseSelectedValue[0], this.klasseSelectedValue[1]);
      } else {
        this.klasseResults = undefined;
        this.klasseSelectItems = [];
        this.klasseSelectedValue = [DcHNoseWorkKlasse.DcHNoseWorkKlasseNone, 0];
        this.klasseDataResultIndex = 0;
      }
    }
    else {
      this.prevSelectedKonkId = this.competionDataSelectedValue.konkId;
      this.competitionDataService.fetchNoseWorkCompetitionData$(this.competionDataSelectedValue.konkId).subscribe(a => {
        this.competitionResponse = a;
        this.setklasseSelectItems(this.competitionResponse);
        this.klasseDataResultsSelectionHandler(this.competitionResponse, this.klasseSelectedValue[0], this.klasseSelectedValue[1]);
      });
    }
  }

  private klasseDataResultsSelectionHandler(competitionResponse: NoseWorkCompetitionResponse, klasse: DcHNoseWorkKlasse, index: number): void {
    this.klasseDataResultIndex = competitionResponse.klasseDataResults.findIndex(b => b.klasse == klasse);
    this.resultaterHelpers.sortKlasseDataResults(competitionResponse.klasseDataResults[this.klasseDataResultIndex], index, 'placer');
    this.dataTableDef = this.getDcHDataTable(index);
    this.klasseResults = competitionResponse.klasseDataResults[this.klasseDataResultIndex].noseWorkResults;
  }

  private setklasseSelectItems(competitionResponse: NoseWorkCompetitionResponse): void {
    this.klasseSelectItems = this.resultaterHelpers.getKlasseSelectItems(competitionResponse.klasseDataResults, false);
    this.klasseSelectedValue = this.klasseSelectItems[0].value;
  }

  private getDcHDataTable(resIndex: number): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.NW;
    dataTable.color = 'primary';

    dataTable.columns = [
      { headerText: '#', field: `noseWorkResultMoments[${resIndex}].placer`, dataType: 'dchPlacer', textalign: 'center' },
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
      { headerText: 'Point', field: `noseWorkResultMoments[${resIndex}].point`, textalign: 'center', dataType: 'number', format: '1.1-1', },
      { headerText: 'Fejl', field: `noseWorkResultMoments[${resIndex}].fejl`, textalign: 'center', dataType: 'number', format: '1.0-0', },
      { headerText: 'Tid', field: `noseWorkResultMoments[${resIndex}].tid`, textalign: 'center', dataType: 'duration', format: 'm:ss.S', },
    ];

    return dataTable;
  }

  private copyEventIdToKonkId(competitionResponses: NoseWorkCompetitionResponse[]) {
    competitionResponses.flatMap(a => a.competitionData).forEach(a => {
      if (a != undefined) {
        a.konkId = a.dchEventId
      }
    });
  }
}
