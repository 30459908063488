<div class="pageHeader">
  <h1>DcH-Program konkurrence resultater</h1>

  <app-hub-connection-status></app-hub-connection-status>

  <div *ngIf="competionDataSelectItems.length>0; else noLiveCompetitions">
    <div class="flex-container wrap">
      <app-dch-select class="competitionSelector" [(selectedValue)]="competionDataSelectedValue" [selectItems]="competionDataSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-dch-select>
      <app-dch-select class="dchKlasseSelector" [(selectedValue)]="klasseSelectedValue" [selectItems]="klasseSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-dch-select>
    </div>

    <h2 *ngIf="competionDataSelectedValue != undefined">
      {{competionDataSelectedValue.konkurrenceNavn}} ({{competionDataSelectedValue.konkurrenceArt | dcHKonkurrenceArtText}})
    </h2>

    <div class="mt-10">
      <app-live-message [liveText]="liveDataResponse?.liveDataResponse?.liveText"></app-live-message>
    </div>

    <div *ngIf="klasseResults != undefined" class="flex-container">
      <mat-radio-group>
        <mat-radio-button checked (click)="onClickMe('placer')" value="1">Placering</mat-radio-button>
        <mat-radio-button (click)="onClickMe('startNo')" value="2">Startnr</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="pageBody">
      <div *ngIf="klasseResults != undefined && klasseResults.lydighedResults.length>0; else noData">
        <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="klasseResults.lydighedResults"></app-dch-data-table>
      </div>
    </div>

  </div>
</div>

<ng-template #noData>
  <div class="noDataToShow">Ingen ekvipager endnu</div>
</ng-template>

<ng-template #noLiveCompetitions>
  <div class="noDataToShow">Ingen live konkurrencer</div>
</ng-template>




